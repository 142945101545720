<template>
  <div id="share-page">
    <b-container>
      <b-row>
        <b-col>
          <b-card
            tag="article"
            class="mt-3"
          >
            <template v-if="blank">
              <div class="blank-txt">
                <div class="container h-100">
                    <div class="row align-items-center h-100">
                        <div class="col-12 mx-auto">
                          {{ this.cardface }}
                        </div>
                    </div>
                </div>
              </div>
            </template>
            <template v-if="!blank">
              <img :src="getImagePath()" alt="" class="card-img-top">
            </template>
            
            <template v-if="msg == ''">
              <b-card-text class="title-text">
                Hi!
              </b-card-text>
              <b-card-text class="title-text">
                You friend {{ this.name }}, <br>
                need "{{ this.cardface }}"
              </b-card-text>
            </template>
            <template v-else>
              <b-card-text class="title-text">
                {{ msg }}
              </b-card-text>
            </template>
            <b-card-text class="support-text">
              {{ this.supportInfo.heart }} friends gave 
              <img src="../assets/icon-heart.png" alt="" class="icon">
            </b-card-text>
            <b-card-text class="support-text">
              {{ this.supportInfo.hug }} friends gave 
              <img src="../assets/icon-hug.png" alt="" class="icon">
            </b-card-text>
            <b-button href="#" variant="primary" v-b-modal.support-modal size="lg" block class="support-btn">Support</b-button>
          </b-card>
          <b-modal 
            id="support-modal"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
          >
            <form ref="form" @submit.stop.prevent="handleSubmit" title="Support Friend">
              <b-form-group
                label="Name"
                label-for="name-input"
                invalid-feedback="Name is required"
              >
                <b-form-input
                  id="name-input"
                  v-model="friendInfo.name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Support Action">
                <b-form-radio-group
                  v-model="friendInfo.type"
                  name="radio-inline"
                >
                <b-form-radio value="hug"><img src="../assets/icon-hug.png" alt="" style="width: 33px; height: auto;"></b-form-radio>
                <b-form-radio value="heart"><img src="../assets/icon-heart.png" alt="" style="width: 33px; height: auto;"></b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </form>
          </b-modal>
        </b-col>
      </b-row>
   </b-container>
  </div>
</template>

<script>
import api from '../apis'
export default {
  name: "SharePage",
  data() {
    return {
      deviceID: '',
      cardID: '',
      cardface: '',
      name: '',
      blank: false,
      msg: '',
      supportInfo: {hug: 0, heart: 0},
      friendInfo: {name: '', type: ''},
    }
  },
  created() {
    this.deviceID = this.$route.query.deviceID
    this.cardID = this.$route.query.cardID
    this.cardface = this.$route.query.cardface
    this.name = this.$route.query.name
    if(this.$route.query.blank) {
      this.blank = this.$route.query.blank == "true" ? true : false
    }
    if(this.$route.query.msg) {
      this.msg = this.$route.query.msg
    }
    console.log(this.deviceID, this.cardface, this.name, this.blank)
    if(!this.deviceID || !this.cardface || !this.name) {
      // this.$router.push('/404')
      return
    }

    api.getSupportInfo(this.deviceID, this.cardID)
      .then(res => {
        this.supportInfo = res.data
      })
      .catch(err => {
        console.log(err)
        // this.$router.push('/404')
        return
      })
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      return valid
    },
    resetModal() {
      this.name = ''
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    getImagePath() {
      return `/Needs/${this.cardface}.png`
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('support-modal')
      })

      //update to api
      api.updateSupportInfo(this.deviceID, this.cardID, this.friendInfo)
        .then(res => {
          this.supportInfo = res.data
          this.$bvToast.toast('Your friend will receive your support.', {
            title: `Support Success`,
            variant: 'success',
            solid: true
          })
        })

    }
  }
  
  
}
</script>

<style>
  #share-page {
    margin: 0 auto;
    color: #222;
  }
  .title-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
  .support-text {
    font-size: 20px;
    font-weight: 500;
  }

  .icon {
    width: 33px;
    height: auto;
    margin-left: 10px;
  }
  .support-btn {
    background-color: #F5CA20 !important;
    border: none !important;
    color: #222 !important;
    font-weight: 500 !important;
    font-size: 30px !important; 
  }
  .card {
    border: 4px solid #F5CA20 !important;
  }
  .blank-txt{
    height: 190px;
    text-align: center;
    font-weight: 600;
    font-size: 48px;
    line-height: 30px;
  }
</style>