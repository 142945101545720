import axios from 'axios'

const apiURL = process.env.VUE_APP_API_URL

const cardList = {
  'health': {name: 'Health', img: 'health.png'},
}

export default {
  getSupportInfo(deviceID, cardID) {
    let url = `${apiURL}/support?deviceID=${deviceID}&cardID=${cardID}`
    return axios.get(url)
      .then(res => res.data)
  },
  updateSupportInfo(deviceID, cardID, friendInfo) {
    let url = `${apiURL}/support?deviceID=${deviceID}&cardID=${cardID}`
    return axios.put(url, friendInfo)
      .then(res => res.data)
  },
  getCardInfo(cardface) {
    return cardList[cardface]
  },
}

