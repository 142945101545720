<template>
  <div>
    <h1>Oops, Something went wrong</h1>
    <p>
      You can go to our <a href="https://www.cardmeditation.com/">website</a> to get support
      </p>
    
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>