import VueRouter from 'vue-router'
import SharePage from './components/SharePage'
import PageNotFound from './components/NotFound'


const routes = [
  {
    path: '/',
    component: SharePage,
  },
  {
    path: '/404',
    component: PageNotFound
  },
  {
    path: '*',
    redirect: {path: '/404'},
  }
]

export default new VueRouter({routes})
